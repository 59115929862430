import FormatCrypto from './FormatCrypto'
import React, { FC, ComponentProps } from 'react'
import CryptoCurrencyIcon from './CryptoCurrencyIcon'
import { zeroAddress } from 'viem'
import { Flex, Text } from './index'

type FormatCryptoCurrencyProps = {
  logoHeight?: number
  borderRadius?: string
  address?: string
  label?: string
  suffix?: string
}

type Props = ComponentProps<typeof FormatCrypto> & FormatCryptoCurrencyProps

const FormatCryptoCurrency: FC<Props> = ({
  amount,
  address = zeroAddress,
  maximumFractionDigits,
  borderRadius = '0%',
  logoHeight = 12,
  textStyle,
  css,
  decimals,
  label,
  suffix,
}) => {
  return (
    <Flex align="center" css={css}>
      <FormatCrypto
        label={label}
        textStyle={textStyle}
        amount={amount}
        maximumFractionDigits={maximumFractionDigits}
        decimals={decimals}
      />
      {suffix && (
        <Text style={textStyle} css={{ marginLeft: '$1' }}>
          {suffix}
        </Text>
      )}
    </Flex>
  )
}

export default FormatCryptoCurrency
